import Footer from "../../components/footer/footer";
import Menu from "../../components/menu/menu";
import MetaTags from "react-meta-tags";
import "../../styles/text.css";
import { useEffect } from "react";

export default function Disclaimer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <MetaTags>
        <title>Isenção de Responsabilidade</title>
      </MetaTags>
      <Menu />
      <div className="container-text">
        <h1>Isenção de Responsabilidade</h1>
        <p>
          As calculadoras e ferramentas disponibilizadas na Calculadora do
          Empreendedor têm caráter meramente informativo e auxiliar. Os
          resultados apresentados são baseados em fórmulas matemáticas e dados
          inseridos pelo usuário. Embora nos esforcemos para garantir a precisão
          dos cálculos, não garantimos a exatidão ou a completude dos
          resultados. É importante ressaltar que as informações e os resultados
          obtidos através das calculadoras não devem ser considerados como
          aconselhamento financeiro, contábil, jurídico ou de qualquer outra
          natureza profissional. Recomendamos que você consulte um profissional
          qualificado para obter orientação específica para a sua situação. A
          Calculadora do Empreendedor não se responsabiliza por quaisquer perdas
          ou danos decorrentes do uso das calculadoras ou das informações
          disponibilizadas no site. O usuário é o único responsável pela
          interpretação e pelo uso dos resultados obtidos. Ao utilizar a
          Calculadora do Empreendedor, você concorda com os termos desta isenção
          de responsabilidade.
        </p>
      </div>
      <Footer />
    </div>
  );
}
