import Footer from "../../components/footer/footer";
import Menu from "../../components/menu/menu";
import MetaTags from "react-meta-tags";

import "../../styles/text.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";

function Sobre() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <MetaTags>
        <title>Sobre</title>
      </MetaTags>
      <Menu />
      <div className="container-text">
        <h1>Sobre a Calculadora do Empreendedor</h1>
        <p>
          Bem-vindo à Calculadora do Empreendedor, seu guia online para cálculos
          empresariais rápidos e eficientes. Criamos esta plataforma com o
          objetivo de fornecer ferramentas práticas e gratuitas para
          empreendedores de todos os níveis, desde aqueles que estão começando
          seu negócio até os mais experientes. Entendemos que o tempo é um
          recurso valioso e que cálculos complexos podem ser um obstáculo. Por
          isso, desenvolvemos calculadoras intuitivas e fáceis de usar, que te
          ajudam a tomar decisões mais informadas e a otimizar a gestão do seu
          negócio.
        </p>
        <br />
        <h3>O que é a Calculadora do Empreendedor?</h3>
        <p>
          Você é um empreendedor que precisa fazer cálculos relacionados ao seu
          negócio, mas não sabe como? Você quer saber quanto cobrar pelo seu
          produto ou serviço, qual é a sua margem de lucro, quantas horas você
          trabalha por mês, quanto você gasta com as suas despesas, etc.? Então
          você veio ao lugar certo!
        </p>
        <p>
          O site www.calculadoraemp.com.br é um serviço online que oferece
          diversas calculadoras para empreendedores, que vão te ajudar a
          gerenciar melhor o seu negócio e tomar decisões mais assertivas. Aqui
          você vai encontrar calculadoras de precificação, margem de lucro,
          horas trabalhadas, somar despesas, e muito mais. Todas as calculadoras
          são fáceis de usar e explicam os conceitos e as fórmulas utilizadas.
          Você também pode gerar um PDF com os resultados das calculadoras, para
          apresentar ou guardar essas informações.
        </p>
        <p>
          O site www.calculadoraemp.com.br é um projeto desenvolvido por
          profissionais que entendem as necessidades e os desafios dos
          empreendedores. O site tem um design simples e intuitivo, que se
          adapta a diferentes tamanhos de tela e dispositivos. O site também tem
          uma política de privacidade e termos e condições que garantem a
          segurança e a transparência dos seus dados. Além disso, o site tem um
          botão de contato que permite que você envie dúvidas, sugestões ou
          reclamações.
        </p>
        <p>
          O site www.calculadoraemp.com.br é o seu parceiro ideal para fazer
          cálculos relacionados ao seu negócio. Acesse agora mesmo e descubra
          como as nossas calculadoras podem te ajudar a empreender com mais
          confiança e sucesso!
        </p>
        <h3>Missão</h3>
        <p>
          Nossa missão é democratizar o acesso a ferramentas de cálculo
          empresarial, oferecendo soluções online gratuitas e de qualidade.
          Queremos simplificar processos complexos e fornecer informações
          relevantes para que empreendedores possam focar no que realmente
          importa: o crescimento e o sucesso de seus negócios. Buscamos
          constantemente aprimorar nossas calculadoras e adicionar novas
          funcionalidades, sempre com o objetivo de atender às necessidades do
          nosso público.
        </p>
        <h3>Visão</h3>
        <p>
          Visualizamos um futuro onde todos os empreendedores, independentemente
          de seu tamanho ou experiência, tenham acesso a ferramentas poderosas e
          fáceis de usar para gerenciar seus negócios com eficiência. Queremos
          ser reconhecidos como a principal referência em calculadoras
          empresariais online, impulsionando o sucesso de empreendedores em todo
          o Brasil.
        </p>
        <h3>Valores</h3>
        <ul>
          <li>
            <span>
              <b>Acessibilidade:</b> Acreditamos que o conhecimento e as
              ferramentas de gestão devem ser acessíveis a todos. Por isso,
              oferecemos nossas calculadoras gratuitamente.
            </span>
          </li>
          <li>
            <span>
              <b>Simplicidade:</b> Priorizamos a criação de interfaces
              intuitivas e fáceis de usar, para que qualquer pessoa possa
              realizar cálculos complexos sem dificuldades.
            </span>
          </li>
          <li>
            <span>
              <b>Precisão: </b> Buscamos a máxima precisão em nossos cálculos,
              utilizando fórmulas e metodologias confiáveis.
            </span>
          </li>
          <li>
            <span>
              <b>Inovação: </b>Estamos sempre buscando novas soluções e
              aprimorando nossas ferramentas para atender às necessidades em
              constante evolução do mercado.
            </span>
          </li>
          <li>
            <span>
              <b>Compromisso com o Empreendedorismo: </b>Acreditamos no poder do
              empreendedorismo para transformar a sociedade e nos dedicamos a
              apoiar os empreendedores em suas jornadas.
            </span>
          </li>
        </ul>
        <br />
        <h3>Contatos</h3>
        <p>
          Você pode entrar em contato conosco através do e-mail:
          contato@calculadoraemp.com.br ou na página <Link to="/contato">Contato</Link>
        </p>
        <h3>Links Importantes</h3>
        <ul>
          <li><Link to="/politica-de-privacidade">Política de Privacidade</Link></li>
          <li><Link to="/termo-de-uso">Termos de Uso</Link></li>
          <li><Link to="/disclaimer">Isenção de Responsabilidade (Disclaimer)</Link></li>
        </ul>
      </div>
      <Footer />
    </div>
  );
}

export default Sobre;
