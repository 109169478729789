import { useState } from "react";
import AddDespesas from "../../../components/add-despesas/add-despesas";
import Home from "../../../components/home/home";
import InfoCustos from "../../../components/info/custos";
function SomarCustos() {
  const [totalDespesas, setTotalDespesas] = useState(0.0);
  const [despesas, setDespesas] = useState([]);
  return (
    <Home
      title="Calculadora do Empreendedor - Soma dos Custos"
      description="Ferramenta simples para somar custos!"
    >
      <h1>Somar Despesas</h1>
      <form className="container-form">
        <fieldset>
          <AddDespesas onChangeTotal={setTotalDespesas} onChangeDespesa={setDespesas}/>
        </fieldset>
      </form>
      <InfoCustos />
    </Home>
  );
}

export default SomarCustos;
