import "../../components/info/info.css";

function InfoLucro() {
  return (
    <div className="container-info">
      <h1>Entendendo a Margem de Lucro e o Markup</h1>
      <p>
        Para vender um produto com lucro, você precisa definir o preço de venda.
        Duas ferramentas importantes para isso são o markup e a margem de lucro.
      </p>
      <h2>O que é Markup? </h2>
      <p>
        Markup é um valor adicionado ao custo de um produto para formar o preço
        de venda. Ele serve como uma referência rápida para definir preços, já
        que cada produto tem custos diferentes. Kotler (2012) define o método de
        usar um markup padrão como o "Preço de Markup", destacando que é mais
        fácil estimar custos do que a demanda, tornando essa abordagem mais
        prática.
      </p>
      <p>
        Em outras palavras, o markup é um multiplicador aplicado sobre o custo
        do produto.
      </p>
      <p>
        Exemplo: Se um produto custa R$ 10,00 e você aplica um markup de 50%, o
        preço de venda será R$ 15,00 (R$ 10,00 + 50% de R$ 10,00).
      </p>
      <div className="info-img"></div>
      <h2>O que é Margem de Lucro?</h2>
      <p>
        A margem de lucro, por outro lado, é a porcentagem do preço de venda que
        representa o seu lucro. Ela mostra quanto você ganha em relação ao preço
        final do produto.
      </p>
      <p>
        Exemplo: Se você vende um produto por R$ 15,00 e ele custou R$ 10,00,
        seu lucro é de R$ 5,00. A margem de lucro é calculada dividindo o lucro
        pelo preço de venda: (R$ 5,00 / R$ 15,00) x 100 = 33,33%.
      </p>
      <h2>A Diferença entre Markup e Margem de Lucro</h2>
      <p>É crucial entender a diferença:</p>
      <p>
        <ul>
          <li>
            <b>Markup:</b> É adicionado ao custo para obter o preço de venda.
          </li>
          <li>
            <b>Margem de Lucro:</b> É calculada sobre o preço de venda e
            representa o percentual de lucro.
          </li>
        </ul>
      </p>
      <h2>Por que não existe Margem de Lucro superior a 100%?</h2>
      <p>
        A margem de lucro representa a proporção do lucro em relação ao preço de
        venda. Se a margem fosse superior a 100%, isso significaria que o seu
        lucro seria maior que o preço pelo qual você vendeu o produto. Isso é
        logicamente impossível.
      </p>
      <p>
        Exemplo: Se você vende um produto por R$ 10,00, o seu lucro nunca poderá
        ser R$ 11,00 ou mais. O máximo que você pode lucrar é R$ 10,00 (quando o
        custo do produto é zero), o que resulta em uma margem de lucro de 100%
        (R$ 10,00 / R$ 10,00 x 100). Qualquer valor acima disso implicaria que
        você está recebendo mais dinheiro do que o valor da venda, o que não faz
        sentido.
      </p>
      <p>
        Em resumo:
        <ul>
          <li>O markup é um multiplicador aplicado sobre o custo.</li>
          <li>
            A margem de lucro é a porcentagem de lucro sobre o preço de venda.
          </li>
          <li>
            A margem de lucro máxima é 100%, representando a situação em que o
            custo do produto é zero.
          </li>
        </ul>
      </p>
    </div>
  );
}

export default InfoLucro;
