import { useState } from "react";
import { formatPercent } from "../../../functions/format";
import {
  formatMoney,
  formatMoneyToString,
} from "../../../functions/format";
import MetaTags from "react-meta-tags";
import Home from "../../../components/home/home";
import InfoLucro from "../../../components/info/margem-de-lucro";
function Lucro() {
  const [margem, setMargem] = useState("0");
  const [custos, setCustos] = useState("0"); console.log(parseFloat(custos));
  const [valordeCusto, setValordeCusto] = useState("0");
  //salvando margem
  const setarMargem = (total: string) => {
    if (isNaN(parseFloat(total))) {
      setMargem("0");
    } else {
      setMargem(total);
    }
  };
  //salvando custos sobre o produto
  const setarCustos = (total: string) => {
    if (isNaN(parseFloat(total))) {
      setCustos("0");
    } else {
      setCustos(total);
    }
  };
  //Calcular valor minimo de venda com custos
  const calcValorcomCustos = () => {
    var valor =
      ((formatMoneyToString(valordeCusto)) /
        (100 - parseFloat(custos))) *
      100;
    valor = valor / 1;
    return valor;
  };
  //Calcular valor minimo sem margem
  const calcValorMinimo = () => {
    const valor =
      (formatMoneyToString(valordeCusto)) / 1;
    if (isNaN(valor)) {
      return 0;
    } else {
      return valor;
    }
  };
  //Calcular com margem
  const calcValorComMargem = () => {
    const valor = (calcValorMinimo() / (100 - parseFloat(margem))) * 100;
    return valor;
  };

  const calcValorcomCustoseMargem = () => {
    var valor = (calcValorComMargem() / (100 - parseFloat(custos))) * 100;
    return valor;
  };
  return (
    <Home
      title="Calculadora do Empreendedor - Margem de Lucro"
      description="Ferramenta simples e grátis para calcular margem de lucro sobre produtos."
    >
      <MetaTags>
        <title>Calculadora do Empreendedor - Margem de Lucro</title>
      </MetaTags>
      <h1>Calcular Margem de Lucro</h1>
      <form className="container-form">
        <fieldset>
          <div className="row-form">
            <div className="container-input container-input-w33">
              <span>Valor de Custo (R$)</span>
              <input
                type="text"
                className="input-control"
                value={formatMoney(valordeCusto)}
                onChange={(e) => setValordeCusto(formatMoney(e.target.value))}
              />
            </div>
            <div className="container-input container-input-w33">
              <span>Margem de Lucro Desejada (%)</span>
              <input
                type="text"
                className="input-control"
                placeholder="30%"
                value={formatPercent(margem)}
                onChange={(e) => setarMargem(formatPercent(e.target.value))}
                maxLength={5}
              />
            </div>
            <div className="container-input container-input-w33">
              <div className="container-help">
                <div className="hover-info">
                  <p>
                    São aqueles custos que serão inseridos no valor final do
                    produto. Ex: Impostos, taxas de cartão de crédito, comissões
                    e etc.
                  </p>
                </div>
              </div>
              <span className="span-help">Custos Sobre o Produto (%)</span>
              <input
                type="text"
                className="input-control"
                placeholder="2%"
                value={formatPercent(custos)}
                onChange={(e) => setarCustos(formatPercent(e.target.value))}
                maxLength={5}
              />
            </div>
          </div>
          <div className="row-form">
            <div className="container-input container-input-w50">
              <div className="container-help">
                <div className="hover-info">
                  <p>
                    O valor mínimo de venda é o custo total de produção ou de compra do produto, acrescido dos custo
                    incidentes sobre o mesmo, você nunca deve vender o seu
                    produto abaixo dessa valor, caso contrário, estará tendo
                    prejuízo.
                  </p>
                </div>
              </div>
              <span className="span-help">Valor Mínimo de Venda</span>
              <input
                type="text"
                className="input-control input-disabled"
                disabled
                value={"R$ " + calcValorcomCustos().toFixed(2)}
              />
            </div>
            <div className="container-input container-input-w50">
              <div className="container-help">
                <div className="hover-info">
                  <p>
                    É o valor considerando a margem de lucro desejada. Para
                    calcularmos o valor do produto com a margem, utilizamos a
                    fórmula:
                    <br />
                    <br />
                    <b>
                      Preço de Venda = (Gastos variáveis unitários (em R$) /
                      (100% - Margem Desejada)(em %)) x 100
                    </b>
                    <br />
                    <br />
                    Nem sempre a margem que você quer é a que se pode praticar,
                    pois dependendo do quanto de margem você definir, o seu
                    produto pode encarecer demais, afugentando os clientes.
                  </p>
                </div>
              </div>
              <span className="span-help">Valor com Margem</span>
              <input
                type="text"
                className="input-control input-disabled"
                disabled
                value={"R$ " + calcValorcomCustoseMargem().toFixed(2)}
              />
            </div>
          </div>
        </fieldset>
      </form>
      <InfoLucro />
    </Home>
  );
}

export default Lucro;
