import { useState } from "react";
import {
  formatMoney,
  formatMoneyToString,
  formatNumber,
} from "../../../functions/format";
import MetaTags from "react-meta-tags";
import Home from "../../../components/home/home";
import InfoHorasTrabalhadas from "../../../components/info/horasTrabalhadas";
function HorasTrabalhadas() {
  const [minutos_trabalhados, setMinutos_trabalhados] = useState("");
  const [valor, setValor] = useState("");
  const [total, setTotal] = useState("");
    
  //setando minutos
  const setarMinutos_trabalhados = (value: any) => {
    setMinutos_trabalhados(value);
    calcHoras(valor, value);
  };
  //setando valor mensal
  const setarValor = (value: any) => {
    setValor(value);
    calcHoras(value, minutos_trabalhados);
  };
  //Calcular horas
  const calcHoras = (thisValor: any, thisMinutos_trabalhados: any) => {
    let calcTotal =
      (formatMoneyToString(thisValor) / 220 / 60) *
      formatNumber(thisMinutos_trabalhados);
    setTotal(calcTotal.toFixed(2));
  };
  return (
    <Home
      title="Calculadora do Empreendedor - Horas Trabalhadas"
      description="Ferramenta simples e grátis para calcular horas trabalhadas"
    >
      <h1  id="horas">Calcular Horas Trabalhadas</h1>
      <form  className="container-form">
        <div className="row-form">
          <div className="container-input container-input-w33">
            <span>Minutos Trabalhados</span>
            <input
              type="text"
              className="input-control"
              placeholder="120"
              value={minutos_trabalhados}
              onChange={(e) =>
                setarMinutos_trabalhados(formatNumber(e.target.value))
              }
            />
          </div>
          <div className="container-input container-input-w33">
            <span>Valor (Mensal R$)</span>
            <input
              type="text"
              className="input-control"
              placeholder="R$ 2.000,00"
              value={valor}
              onChange={(e) => setarValor(formatMoney(e.target.value))}
            />
          </div>
          <div className="container-input container-input-w33">
            <span>Total</span>
            <input
              type="text"
              className="input-control input-disabled"
              disabled
              value={formatMoney(total)}
            />
          </div>
        </div>
      </form>
      <InfoHorasTrabalhadas/>
    </Home>
  );
}

export default HorasTrabalhadas;
