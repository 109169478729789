import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sobre from "./pages/sobre/sobre";
import Contato from "./pages/contato/contato";
import Privacidade from "./pages/privacidade/privacidade";
import TermoDeUso from "./pages/privacidade/termo-de-uso";
import Produto from "./pages/calculadora/calc-produto/calc-produto";
import Lucro from './pages/calculadora/calc-lucro/calc-lucro';
import HorasTrabalhadas from './pages/calculadora/calc-horas/calc-horas';
import SomarCustos from './pages/calculadora/calc-custos/calc-custos';
import Disclaimer from "./pages/privacidade/disclaimer";

function Rotas() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Produto />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/politica-de-privacidade" element={<Privacidade />} />
        <Route path="/termo-de-uso" element={<TermoDeUso />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/margem-de-lucro" element={<Lucro />} />
        <Route path="/horas-trabalhadas" element={<HorasTrabalhadas />} />
        <Route path="/somar-despesas" element={<SomarCustos />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Rotas;
