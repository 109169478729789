let lgpdHtml = `
  <div class="div-lgpd">
    <div class="lgpd-left">
      Nós utilizamos cookie para melhorar sua experiência de usuário. <br />
      Para conferir detalhadamente todos os cookies utilizados, leia nossa 
      <a href="/politica-de-privacidade">política de privacidade</a>, 
      <a href="/disclaimer">isenção de responsabilidade</a> e nossos <a href="/termo-de-uso">termos e condições.</a>
      <br/>
      Ao utilizar a Calculadora do Empreendedor, você <b>concorda com os termos apresentados.</b>
    </div>
    <div class="lgpd-right">
      <button>ACEITO</button>
    </div>
  </div>
`;

let lsContent = localStorage.getItem('lgpd');
if (!lsContent) {
  document.body.innerHTML += lgpdHtml;

  let lgpdArea = document.querySelector('.div-lgpd');
  let lgpdButton = lgpdArea.querySelector('button');

  lgpdButton.addEventListener('click', () =>{
    lgpdArea.remove();
    localStorage.setItem('lgpd','Aceito');
  })

}