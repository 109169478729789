import "../../components/info/info.css";
import "../../styles/text.css";
import { useRef } from "react";

function InfoHorasTrabalhadas() {
  const thisForm = useRef(null);

  return (
    <div ref={thisForm} className="container-info">
      <h1>Calculando as Horas Trabalhadas</h1>
      <p>
        Abrir uma empresa traz muitas dúvidas, e uma delas é: como calcular as
        horas trabalhadas dos funcionários? Muitos materiais explicam isso de
        forma complicada, mas a gente vai descomplicar para você. Este guia é
        perfeito para quem está começando e precisa entender o básico sobre
        jornada de trabalho.
      </p>
      <h2>O que é Jornada de Trabalho?</h2>
      <p>
        Jornada de trabalho é o tempo que o funcionário fica à disposição da sua
        empresa. A lei (Constituição Federal) define que a jornada máxima é de 8
        horas por dia e 44 horas por semana. Isso significa que um funcionário
        pode trabalhar 8 horas por dia, 6 dias por semana, ou uma combinação que
        some 44 horas semanais. Menos horas também são possíveis, dependendo do
        acordo entre você e o funcionário. Horas trabalhadas além desse limite
        são consideradas horas extras.
      </p>
      <h2>Como Calcular as Horas Trabalhadas Normais?</h2>
      <p>
        Para calcular o valor da hora normal de trabalho, você precisa de duas
        informações:
        <ul>
          <li>
            <b>Carga horária semanal:</b> Quantas horas o funcionário trabalha
            por semana.
          </li>
          <li>
            <b>Salário mensal:</b> Quanto o funcionário recebe por mês.
          </li>
        </ul>
        A conta é simples: você divide o salário mensal pelo número de horas
        trabalhadas no mês. Para facilitar, consideramos que um mês tem, em
        média, 5 semanas.
      </p>
      <p className="p-h3">
        <b>Exemplo 1: Jornada de 44 horas semanais:</b>
        <ul>
          <li>
            <b>Horas mensais:</b> 44 horas/semana x 5 semanas/mês = 220
            horas/mês
          </li>
          <li>
            <b>Salário:</b> R$ 2.000,00
          </li>
          <li>
            <b>Valor da hora normal:</b> R$ 2.000,00 / 220 horas = R$ 9,09 por
            hora (aproximadamente)
          </li>
        </ul>
      </p>
      <p className="p-h3">
        <b>Exemplo 2: Jornada de 36 horas semanais:</b>
        <ul>
          <li>
            <b>Horas mensais:</b> 36 horas/semana x 5 semanas/mês = 180
            horas/mês
          </li>
          <li>
            <b>Salário:</b> R$ 1.500,00
          </li>
          <li>
            <b>Valor da hora normal:</b> R$ 1.500,00 / 180 horas = R$ 8,33 por
            hora (aproximadamente)
          </li>
        </ul>
      </p>

      <h2>Como Calcular o Valor da Hora Extra?</h2>
      <p>
        A hora extra é mais cara que a hora normal. A lei determina que ela deve
        valer, no mínimo, 50% a mais que a hora normal.
      </p>
      <p className="p-h3">
        <b>Exemplo 1: (salário R$ 9,09/hora)</b>
        <ul>
          <li>
            <b>Acréscimo de 50%:</b> R$ 9,09 x 0,50 = R$ 4,55
          </li>
          <li>
            <b>Valor da hora extra:</b> R$ 9,09 + R$ 4,55 = R$ 13,64
          </li>
        </ul>
      </p>
      <p className="p-h3">
        <b>Exemplo 2: (salário R$ 8,33/hora)</b>
        <ul>
          <li>
            <b>Acréscimo de 50%:</b> R$ 8,33 x 0,50 = R$ 4,17
          </li>
          <li>
            <b>Valor da hora extra:</b> R$ 8,33 + R$ 4,17 = R$ 12,50
          </li>
        </ul>
      </p>
      <p>
        Para calcular o valor total das horas extras no mês, multiplique o valor
        da hora extra pela quantidade de horas extras trabalhadas.
      </p>
      <h2>Horas Extras em Finais de Semana e Feriados:</h2>
      <p>
        As horas extras trabalhadas em finais de semana e feriados geralmente
        valem 100% a mais que a hora normal, ou seja, o dobro.
      </p>
      <h2>E a Jornada Noturna?</h2>
      <p>
        A jornada noturna tem regras próprias. A hora noturna não tem 60
        minutos, mas sim 52 minutos e 30 segundos. Além disso, o trabalhador
        noturno tem direito a um adicional noturno, que também é calculado sobre
        o valor da hora normal. O cálculo do adicional noturno é mais complexo e
        pode variar dependendo da legislação e do acordo coletivo da categoria.
        É recomendável consultar um profissional de contabilidade ou direito
        trabalhista para informações mais precisas sobre o adicional noturno.
      </p>
      <p><b>Nossa calculadora realiza o cálculo automaticamente para você, de forma simples e eficaz, seguindo o cálculo apresentado neste artigo.</b> </p>
    </div>
  );
}

export default InfoHorasTrabalhadas;
