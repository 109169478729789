import "../../components/info/info.css";
import "../../styles/text.css";
import CustoUnitario from "./Custo-Unitario.png";
import CustoAcucar from "./CustoAcucar.png";
import CustosTotaisInsumos from "./CustosTotaisInsumos.png";
import CustoGas from "./CustoGas.png";
import MaodeObra from "./MaodeObra.png";
import ValoresFinais from "./ValoresFinais.png";
import Resultado from "./Resultado.png";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";

function InfoPrecificacao() {
  const thisForm = useRef(null);
  const scrollToSection = (elementRef: any) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <div ref={thisForm} className="container-info">
      <h1>Como Utilizar a ferramenta de precificação?</h1>
      <h2>1. Reúna os Materiais, Custos e Rendimento</h2>
      <p>Para este exemplo, usaremos uma receita de pudim de leite:</p>
      <h3>Ingredientes:</h3>
      <p>
        <ul className="p-h3">
          <li>1 lata de leite condensado</li>
          <li>3 ovos inteiros</li>
          <li>
            1 lata de leite (usando a lata de leite condensado como medida)
          </li>
          <li>1 xícara (chá) de açúcar</li>
          <li>1/2 xícara de água</li>
        </ul>
      </p>
      <h3>Tempo de Preparo:</h3>
      <p className="p-h3">1 hora (60 minutos)</p>
      <h3>Rendimento:</h3>
      <p className="p-h3">8 porções</p>
      <h3>Custos dos Ingredientes:</h3>
      <p className="p-h3">
        <ul>
          <li>1 lata de leite condensado: R$ 8,00</li>
          <li>1 dúzia de ovos: R$ 10,00</li>
          <li>1 caixa de leite: R$ 6,00</li>
          <li>1 kg de açúcar: R$ 5,00</li>
          <li>Embalagem de pudim (pacote com 300 unidades): R$ 67,45</li>
        </ul>
        <p>
          Observação: Para simplificar o exemplo, desconsideraremos o custo da
          água. No entanto, você pode incluí-lo no cálculo se necessário.
        </p>
      </p>
      <h2>2. Inclua os Insumos na Calculadora</h2>
      <p>
        Na Calculadora do Empreendedor, siga os passos abaixo para cada
        ingrediente (insumo).
      </p>
      <p>
        <ul>
          <li>
            <b>Campo "Insumo":</b> Insira o nome do ingrediente (ex: Leite
            Condensado, Ovos, Leite, Açúcar, Embalagem).
          </li>
          <li>
            <b>Campo "Quantidade Utilizada":</b> Insira a quantidade usada na
            receita. Use a unidade de medida que preferir (unidade, kg, grama,
            etc.).
          </li>
          <li>
            <b>Custo Unitário:</b> Clique neste campo. Uma janela se abrirá para
            inserir o custo total do produto e a quantidade total da
            embalagem/pacote.
            <ul>
              <li>
                <b>Campo "Valor Total do Produto":</b> Insira o valor pago pelo
                produto (ex: R$ 5,00 pelo açúcar).
              </li>
              <li>
                <b>Campo "Quantidade":</b> Insira a quantidade total da
                embalagem, usando a mesma unidade de medida que você usou em
                "Quantidade Utilizada" (ex: 1000 gramas de açúcar).
              </li>
              <li>
                Clique em <b>"Salvar"</b> .
              </li>
            </ul>
          </li>
        </ul>
      </p>
      <img src={CustoUnitario} alt="Custo Unitário" />
      <p>
        A calculadora calculará automaticamente o <b>Custo Unitário</b>.
      </p>
      <p>
        Exemplo com o açúcar: Ao inserir R$ 5,00 como{" "}
        <b>"Valor Total do Produto"</b> e 1000 gramas como <b>"Quantidade"</b>,
        a calculadora mostrará o custo unitário de R$ 0,005 por grama. Como
        usamos 200 gramas de açúcar na receita, o custo total do açúcar será de
        R$ 1,00 (200 x R$ 0,005).
      </p>
      <img src={CustoAcucar} alt="Custo do Açucar" />
      <p>
        Repita esse processo para todos os ingredientes, clicando no botão{" "}
        <b>"+"</b>
        ao lado de <b>"Custo Total de Insumos"</b> para adicionar novos insumos.
        Para excluir um insumo, clique no <b>"X"</b> ao lado dele.
      </p>
      <p>
        Incluímos também 8 embalagens de pudim no cálculo. O custo total dos
        insumos para esta receita é de R$ 14,50.
      </p>
      <img src={CustosTotaisInsumos} alt="Custos totais insumos" />
      <h2>3. Calcule as Despesas</h2>
      <p>
        As despesas mais comuns são energia elétrica, água e gás. Você pode
        incluir outras despesas, se necessário. Para esta receita, incluiremos o
        custo de 45 minutos de uso do forno a gás em fogo médio, estimado em R$
        1,30 (utilize as ferramentas de cálculo de energia elétrica e gás da
        Calculadora do Empreendedor para obter valores mais precisos para sua
        realidade).
      </p>
      <img src={CustoGas} alt="Custo Gás de Cozinha" />
      <h2>4. Inclua os Custos de Mão de Obra</h2>
      <p>
        No campo <b>"Mão de Obra"</b>:
      </p>
      <p>
        <ul>
          <li>
            <b>Descrição:</b> Descreva a atividade (ex: Preparo do Pudim).
          </li>
          <li>
            <b>Minutos Trabalhados:</b> Insira o tempo gasto na atividade em
            minutos (ex: 60 minutos).
          </li>
          <li>
            <b>Valor (Mensal):</b> Insira o valor mensal pago pela mão de obra
            (ex: um salário mínimo de R$1.320,00 em 2023 - atualize esse valor
            para o salário vigente).
          </li>
        </ul>
      </p>
      <p>
        A calculadora calculará o custo proporcional da mão de obra para a
        receita. Para o exemplo, considerando um salário mínimo de R$1.320,00 e
        60 minutos de trabalho dedicados ao pudim, o calculo seria: (1320/220) /
        8 = 0,75 reais, onde 220 são as horas trabalhadas mensais e 8 o numero
        de pudins produzidos.
      </p>
      <img src={MaodeObra} alt="Mão de obra" />
      <p>
        Para entender mais sobre o cálculo das horas trabalhadas vá até a página <Link onClick={() => scrollToSection(thisForm)} to="/horas-trabalhadas">
          Horas Trabalhadas
        </Link>, nessa ferramenta de precificação utilizamos uma carga horária de 44 horas semanais. {" "}
        
      </p>
      <h2>5. Resultados Finais</h2>
      <p>
        Após preencher todos os dados, você verá três resultados principais:
      </p>
      <p>
        <ul>
          <li>
            <b>Valor Mínimo de Venda:</b> O custo total de produção por porção,
            sem margem de lucro.
          </li>
          <li>
            <b>Valor com Margem:</b> O preço de venda por porção, incluindo a
            margem de lucro desejada.
          </li>
          <li>
            <b>Ponto de Equilíbrio:</b>
            <ul>
              <li>
                <b>Custo Total de Fabricação:</b> O valor total gasto para
                produzir todos os pudins.
              </li>
              <li>
                <b>Quantidade Mínima para Venda:</b> A quantidade mínima de
                porções que precisam ser vendidas para cobrir os custos de
                produção.
              </li>
            </ul>
          </li>
        </ul>
      </p>
      <p>
        Para este exemplo, usaremos um redimento de 8 unidades, uma margem de
        lucro de 50% e uma taxa de cartão de crédito de 2%.
      </p>
      <img src={ValoresFinais} alt="Valores Finais" />
      <p>
        Exemplo de Resultados:
        <ul>
          <li>
            <b>Valor Mínimo de Venda</b> (com 2% da taxa do cartão): R$ 2,90
            (valor hipotético)
          </li>
          <li>
            <b>Valor com Margem</b> (50% de lucro): R$ 5,79 (valor hipotético)
          </li>
          <li>
            <b>Custo Total de Fabricação</b>: R$ 23,16 (valor hipotético)
          </li>
          <li>
            <b>Quantidade Mínima para Venda</b>: 5 porções (valor hipotético)
          </li>
        </ul>
      </p>
      <p>
        Isso significa que cada fatia de pudim custa R$ 2,90 para ser produzida
        (já incluindo a taxa do cartão). Para obter um lucro de 50%, o preço de
        venda deve ser R$ 5,79. É necessário vender pelo menos 5 fatias para
        cobrir o custo total de R$ 23,16. A partir da sexta fatia, você começa a
        ter lucro.
      </p>
      <img src={Resultado} alt="Resultado" />
      <p>
        Você pode <b>gerar um PDF gratuito</b> com todas as informações
        preenchidas.
      </p>
      <p>
        Para entender mais sobre o cálculo da margem de lucro vá até a página{" "}
        <Link onClick={() => scrollToSection(thisForm)} to="/margem-de-lucro">
          Margem de Lucro
        </Link>
      </p>
    </div>
  );
}

export default InfoPrecificacao;
