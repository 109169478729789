import "../../components/info/info.css";
import "../../styles/text.css";
import { useRef } from "react";

function InfoCustos() {
  const thisForm = useRef(null);
  return (
    <div ref={thisForm} className="container-info">
      <h1>Como identificar os custos?</h1>
      <h2>Gastos: A Base da Precificação</h2>
      <p>
        O comércio existe há milênios, e desde os tempos antigos, o objetivo
        principal é comprar bem para vender melhor e obter lucro. Para isso, é
        fundamental conhecer os gastos envolvidos no seu negócio.
      </p>
      <p>
        Um erro comum em pequenas empresas é misturar as despesas pessoais com
        as do negócio, o que causa uma grande confusão financeira. O primeiro
        passo para precificar corretamente é organizar os gastos da empresa,
        separando-os em duas categorias principais: gastos fixos e gastos
        variáveis.
      </p>
      <h2>Gastos Fixos</h2>
      <p>
        Gastos fixos são aqueles que não mudam, independentemente da sua
        produção ou vendas. Eles são como contas que você paga todo mês, mesmo
        que não venda nada. Exemplos: Aluguel,Água, Luz, Salários (da equipe
        administrativa, por exemplo), Contas de internet e etc.
      </p>
      <p>
        Para calcular quanto cada produto "carrega" de gasto fixo, você precisa
        dividir o total dos seus gastos fixos mensais pela quantidade de
        produtos que você produz ou espera vender no mesmo período. Isso é
        chamado de rateio.
      </p>
      <p>
        Exemplo: Se seus gastos fixos somam R$ 2.000,00 por mês e você produz
        100 unidades de um produto, cada unidade "carrega" R$ 20,00 de gastos
        fixos (R$ 2.000,00 / 100 unidades = R$ 20,00/unidade).
      </p>
      <p>
        A forma de ratear os gastos fixos pode variar dependendo da sua empresa
        e da estratégia de precificação para cada produto
      </p>
      <h2>Gastos Variáveis</h2>
      <p>
        Gastos variáveis são aqueles que mudam de acordo com o volume de
        produção ou vendas. Se você produz mais, gasta mais; se produz menos,
        gasta menos. Exemplos: Matéria-prima (ingredientes, tecidos, etc.),
        Embalagens, Comissões de vendas, Impostos sobre vendas, Frete (quando
        varia conforme a quantidade vendida) e etc.
      </p>
      <p>
        Se a produção aumentar, a quantidade de matéria-prima necessária também
        aumenta, elevando o custo variável total. No entanto, um aumento na
        demanda por matéria-prima pode, em alguns casos, levar a uma redução no
        preço unitário da matéria-prima, devido a compras em maior escala.
      </p>
      <p>
        Para calcular os gastos variáveis, analise o processo de produção e
        identifique os custos diretamente ligados à fabricação de cada produto.
        Assim, você poderá determinar os custos unitários e totais.
      </p>
      <p>
        Exemplo: Se você usa R$ 5,00 de matéria-prima para produzir um único
        produto, o custo variável unitário é R$ 5,00. Se você produzir 10
        produtos, o custo variável total será R$ 50,00.
      </p>
    </div>
  );
}

export default InfoCustos;
